<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-center">
        <h1>
          Talenten Competentie Analyse
        </h1>
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12" v-if="loading">
        <dashboard-own-scan-loading scan />
      </v-col>

      <v-col cols="12" v-else-if="rapportAvailable">
        <v-row>
          <v-col cols="12">
            <pdf
                :src="imageUrl"
            ></pdf>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-divider></v-divider>

            <p class="font-weight-regular">
              {{ description }}
            </p>
          </v-col>

          <v-col cols="12" class="text-center py-0">
            <v-btn color="primary" block @click="downloadRapport" :loading="downloadLoading" :disabled="downloadLoading">Download volledig profiel
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" v-else>
        <dashboard-own-scan-not-filled analyse />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import DashboardOwnScanNotFilled from "@/pages/dashboard/own/notfilled";
import pdf from 'vue-pdf'
import DashboardOwnScanLoading from "@/pages/dashboard/own/loading";
import {mapGetters} from "vuex";
import apiClient from "@/services/apiClient";
import axios from "axios";

export default {
  name: "dashboardOwnScanTCA",
  components: {
    DashboardOwnScanLoading,
    DashboardOwnScanNotFilled,
    pdf
  },
  data() {
    return {
      loading: true,
      rapportAvailable: false,
      imageUrl: "",
      downloadUrl: "",
      downloadLoading: false,
      description: "",
    }
  },
  computed: {
    ...mapGetters({
      token: "auth/accessToken",
      scan: "user/scan",
      user: "user/user"
    }),
  },
  mounted() {
    if (this.scan.tca.access && this.scan.tca.completed) {
      this.rapportAvailable = true;
    }

    this.imageUrl = process.env.VUE_APP_API_URL + "scan/tca/image?token=" + this.token;
    this.downloadUrl = process.env.VUE_APP_API_URL + "scan/tca/download.pdf?token=" + this.token;

    apiClient.get("scan/tca/data").then(response => {
      this.showSuccess = true;

      this.description = response.data.text;

      this.loading = false;
    }).catch(error => {
      this.loading = false;
    });
  },
  methods: {
    stopLoading() {
      this.downloadLoading = false;
    },
    downloadRapport() {
      this.downloadLoading = true;

      window.open(this.downloadUrl, "_blank");

      setTimeout(this.stopLoading, 2000);
    },
  }
};
</script>
